var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"dark":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.tmodelsList,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('h2',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""}},[_vm._v("mdi-card-text")]),_vm._v("TEST MODELS")],1)]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"fab":"","dark":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.newTmodel($event)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-items',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"mx-auto"},[_c('v-switch',{attrs:{"color":"success"},on:{"change":function($event){return _vm.toogleActive(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1),(item.active)?_c('v-col',{staticClass:"mx-auto"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-2 white--text",on:{"click":function($event){$event.stopPropagation();return _vm.mailTmodel(item)}}},[_vm._v(" mdi-email-send ")])],1)]}}],null,true)},[_c('span',[_vm._v("Send Email Notification")])])],1):_vm._e()],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"success--text",attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();return _vm.runTmodel(item)}}},[_vm._v(" mdi-check-network ")])],1)]}}],null,true)},[_c('span',[_vm._v("Run Test Model Form")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){$event.stopPropagation();return _vm.editTmodel(item)}}},[_vm._v(" mdi-file-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("View/Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.removeTmodel(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])})],1)],1)],1),_c('tmodel-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }