import store from '@/store'
import axios from "axios";

class UploadKeyService {

	upload(file, onUploadProgress) {

		let formData = new FormData();

		formData.append("file", file);

		return axios.post("/tmodel/upload-key", formData, {
			headers: {
				"Token": store.state.authModule.token,
				"Content-Type": "multipart/form-data"
			},
			onUploadProgress
		});
	}


}

export default new UploadKeyService();