<template>
	<Layout>
		<v-container>
      <v-row>
        <v-col>
					 <v-data-table
					 	dark
						:loading="loading"
						:headers="headers"
						:items="tmodelsList"
						:search="search"
          >
						<!-- slot Top table -->
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><h2><v-icon large class="mr-2">mdi-card-text</v-icon>TEST MODELS</h2></v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn class="mt-2" fab dark color="primary" @click.stop="newTmodel">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
              <v-toolbar flat>
                <v-toolbar-items>
                  <v-text-field
										v-model="search"
										append-icon="mdi-magnify"
										label="Search"
										single-line
										hide-details
                  />
                </v-toolbar-items>
              </v-toolbar>
            </template>

            <!-- template Active -->
            <template v-slot:item.active="{ item }">
              <v-layout justify-center>
								<v-row align="center">
									<v-col class="mx-auto">
                		<v-switch color="success" v-model="item.active" @change="toogleActive(item)" />
									</v-col>
									<v-col class="mx-auto" v-if="item.active">
										<v-tooltip top>
											<span>Send Email Notification</span>
											<template v-slot:activator="{ on, attrs }">
												<v-btn icon v-bind="attrs" v-on="on">
													<v-icon class="mx-2 white--text" @click.stop="mailTmodel(item)">
														mdi-email-send
													</v-icon>
												</v-btn>
											</template>
										</v-tooltip>
									</v-col>
								</v-row>
              </v-layout>
            </template>

            <!-- Actions -->
            <template v-slot:item.action="{ item }">
							<v-tooltip top>
								<span>Run Test Model Form</span>
								<template v-slot:activator="{ on, attrs }">
									<v-btn class="mx-2" icon v-bind="attrs" v-on="on">
										<v-icon large class="success--text" @click.stop="runTmodel(item)">
											mdi-check-network
										</v-icon>
									</v-btn>
								</template>
							</v-tooltip>
							<v-tooltip top>
								<span>View/Edit</span>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon
											class="mx-2"
											@click.stop="editTmodel(item)"
										>
											mdi-file-edit
										</v-icon>
									</v-btn>
								</template>
							</v-tooltip>
							<v-tooltip top>
								<span>Delete</span>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon
											class="mx-2"
											@click="removeTmodel(item)"
											color="red"
										>
											mdi-delete
										</v-icon>
									</v-btn>
								</template>
							</v-tooltip>
            </template>

					</v-data-table>
				</v-col>
			</v-row>
		</v-container>

		<tmodel-dialog />

	</Layout>
</template>

<script>
	import axios from 'axios';
	import { mapState, mapActions } from "vuex";
	import store from '@/store'
	import TmodelDialog from '@/components/dialog/TmodelDialog.vue'
	const header = {"Token": store.state.authModule.token}
	const configuration = {headers: header}
	export default {
		name: 'TmodelView',
		components: { TmodelDialog },
		data: ()=>({
			tmodelsList: [],
			search: '',
			headers: [
				{ text: 'TITLE', value: 'title', align: 'start'},
				{ text: 'PROJECT', value: 'project.title', align: 'start', },
				{ text: 'PROJECT', value: 'project.user.company', align: 'start', },
				{ text: 'STATUS', value: 'active', align: 'left', },
				{text: '', value: 'action', sortable: false, width:'15%',lign: 'end', },
			],
			json_fields: {
				'TITLE': 'title',
				'PROJECT': 'project.title',
			},
			items: [],
			editedIndex: -1,
			editedItem: [{
				_id: '',
				project: '',
				projectId: '',
				modelId: '',
				variables: [],
			}],
			loading: false
		}),
		mounted(){
			this.getData()
		},
		methods: {
			...mapActions('tmodelModule',['getTmodels','deleteTmodel']),
			...mapActions('projectModule',['getProjects']),

			async getData() {
				this.loading = true
				try {
					const models = await this.getTmodels()
					const projects = await this.getProjects()
				} catch (error) {
					console.log(error)
				} finally {
					this.loading = false
				}
			},

			newTmodel(){
				this.$store.commit(
					'tmodelModule/SET_TMODEL',
					{
						_id: '',
						project: '',
						projectId: '',
						modelId: '',
						variables: [],
					},
					{ root: true }
				)
				this.$store.commit('SET_EDITMODE', 0)
				this.$store.commit('setDialog', true, { root: true })
			},
			editTmodel(item){
				console.log(item)
				this.editedItem = Object.assign({}, item)
				this.editedItem.runMax = ( this.editedItem.runMax != null && this.editedItem.runMax != '') ? this.editedItem.runMax : 5
				this.editedItem.runAcum = ( this.editedItem.runAcum != null && this.editedItem.runAcum != '') ? this.editedItem.runAcum : 0
				this.$store.commit('SET_EDITMODE', 1)
				this.$store.commit('tmodelModule/SET_TMODEL', this.editedItem, { root: true } )
				this.$store.commit('setDialog', true, { root: true })
			},
			async toogleActive(item){
				try {
					//const response = await axios.put('tmodel/toggle-active', {'_id': item._id, 'active': item.active},configuration)
					let res = false
					let message = ""
					let color = ""

					if (item.active){
						message = 'If the status is active (green color) the user/client can run the test form, continue?'
						color = 'success'
					} else {
						message = "If the status is inactive (gray color) the user/client can't run the test form, continue?"
						color = 'warning'
					}
					res = await this.$confirm(message, {
						title: 'Warning: Toggle Status',
						color: color,
						icon: 'mdi-timeline-check',
						buttonTrueColor: 'primary',
					})
					if (res){
						const response = await axios.put('tmodel/toggle-active', {'_id': item._id, 'active': item.active, 'projectId': item.project._id},configuration)

						if (response.status == 200){
							this.$store.commit('SET_ALERT_MESSAGE', {
								show: true,
								color: 'success',
								message: 'Status Updated successfully',
								timeout: 4000
							}, {root: true})
						} else {
							this.$store.commit('SET_ALERT_MESSAGE', {
								show: true,
								color: 'error',
								message: response.statusText,
								timeout: 4000
							}, {root: true})
						}
					} else {
						this.getTmodels()
					}
				} catch (e) {
					console.log(e.message)
				}

			},

			async removeTmodel(item){
				await this.$confirm('Are you sure you want to delete this Tmodel?', {
					title: 'Warning',
					color: 'error',
					icon: 'mdi-hand',
					buttonTrueColor: 'primary',
				})
						.then(res => {
							if (res){
								const id = item._id
								this.deleteTmodel(id)
								const index = this.tmodels.findIndex(item => item._id === id);
								this.tmodels.splice(index, 1);
							}
						})
			},
			runTmodel(item){
				this.$router.push({name: 'RunTmodelView', params: {id: item._id} })
			},
			async mailTmodel(item){

				const res = await this.$confirm('Send mailing notification to user/client?', {
					title: 'SEND MAIL NOTIFICATION',
					color: 'warning',
					icon: 'mdi-email-send-outline',
					buttonTrueColor: 'primary',
				})
				if (res){
					try {
						const data = {
							'cc': 'h2jose@icloud.com',
							'testId': item._id,
							'user_fullName': item.project.user.fullName,
							'user_email': item.project.user.email,
							'project_title': item.project.title,
							'project_description': item.project.description,
							'tmodel_targetVarLabel': item.project.description
						}
						const response = await axios.post('tmodel/send-notification', data,configuration)
						if (response.status == 200){
							this.$store.commit('SET_ALERT_MESSAGE', {
								show: true,
								color: 'success',
								message: 'Mail sent successfully',
								timeout: 4000
							}, {root: true})
						}
					} catch (e) {
						this.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'error',
							message: e.message,
							timeout: 4000
						}, {root: true})
					}
				}
			}
		},
		computed: {
			//...mapState(['loading']),
			...mapState('tmodelModule',['tmodels','tmodel']),
		},
		watch: {
			'tmodels': function () {
				this.tmodelsList = this.tmodels;
			}
		}

	}
</script>
