<template>
	<v-row justify="center">
		<!-- Test Model detail -->
		<v-dialog v-model="dialog" fullscreen  transition="dialog-bottom-transition">
			<v-card dark>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ formTitle }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :loading="saving" dark text @click.stop="validate()" > <v-icon class="mr-3">mdi-check-circle</v-icon> SAVE</v-btn>
            <v-btn dark text @click="closeDialog"> <v-icon >mdi-close</v-icon> Cancel</v-btn>
          </v-toolbar-items>
        </v-toolbar>
				 <v-card-text>
          <v-container  class="pt-5 white rounded mt-5 elevation-10 grey darken-3" >
            <v-form ref="form" v-model="valid" enctype="multipart/form-data">
							<v-row justify="center">
                <v-col cols="12" sm="5" class="grey darken-2">
									<h2 class="mb-2">Internal Identification</h2>
									<v-textarea
										dense
										solo
										v-model="editedItem.title"
										label="TITLE"
										:value="editedItem.title"
										rows="2"
										:rules="[rules.required]"
                  />
									<v-select
										dense
										v-model="editedItem.project"
										:items="projects"
										item-text="title"
										item-value="_id"
										return-object
										label="Project"
									/>
									<h2 class="mb-2">Form Header</h2>
									<v-textarea
										class="mb-2"
										dense
										solo
										v-model="editedItem.titleForm"
										label="TITLE FORM"
										:value="editedItem.titleForm"
										rows="2"
										:rules="[rules.required]"
                  />
									<v-textarea
										class="mb-2"
										dense
										solo
										v-model="editedItem.subtitleForm"
										label="SUBTITLE FORM"
										:value="editedItem.subtitleForm"
										rows="3"
										:rules="[rules.required]"
                  />
									<h2 class="mb-2">Target Variable Input</h2>
									<v-text-field
										solo
										label="Label"
										v-model="editedItem.targetVarLabel"
										:rules="[rules.required]"
                  />
									<v-text-field
										solo
										label="Hint"
										v-model="editedItem.targetVarHint"
										:rules="[rules.required]"
                  />
									<v-text-field
										label="Limit allowed Running Model"
										v-model="editedItem.runMax"
										type="number"
										:rules="[rules.required]"
                  />
									<v-text-field
										label="Accumulated Running Model"
										v-model="editedItem.runAcum"
										type="number"
                  />

								</v-col>
								<v-col cols="12" sm="7">
									<h2 class="mb-2">Google Identification</h2>
									<div class="d-flex">
										<v-text-field
											class="mx-1"
											dense
											filled
											label="Project Id"
											v-model="editedItem.projectId"
											:rules="[rules.required]"
										/>
										<v-text-field
											class="mx-1"
											dense
											filled
											label="Model Id"
											v-model="editedItem.modelId"
											:rules="[rules.required]"
										/>
										<section class="px-5 text-right">
											<h4>Key Service Account</h4>
											<span v-if="editedItem.keyJson != ''">
												<a class="white--text" href="#" @click="downloadKeyJson">key.json </a>
												<v-icon class="red--text" @click="removeKeyJson">mdi-delete</v-icon>
											</span>
											<v-btn
												v-else
												class="mt-2"
												small
												white--text
												color="secondary"
												@click.stop="dialogKeyJson = true"
											>
												UPLOAD <v-icon class="ml-2" dark>mdi-upload</v-icon>
											</v-btn>
										</section>

									</div>

									<!-- Variables array -->
									<variables-component :variables="variables" @emitVariables="setVariables" />
									<!-- .Variables array -->
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				 </v-card-text>
			</v-card>
		</v-dialog>
		<!-- .Test Model detail -->

		<!-- KeyJson upload -->
		<v-dialog dark  v-model="dialogKeyJson" persistent max-width="470">
      <v-card class="blue-grey lighten-1">
        <v-card-title class="headline">Upload Key Json File</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <v-file-input
									ref="fileData"
									accept=".json"
									@change="selectFile"
									show-size
									label="Choose File"
									hint="google Service Account formats (json)"
									persistent-hint
								/>

              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn secondary text @click="dialogKeyJson = false">Cancel</v-btn>
          <v-btn class="primary darken-2" :loading="uploading"  @click="uploadFile">Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
		<!-- .KeyJson upload -->

	</v-row>
</template>

<script>
	import {mapState, mapActions} from 'vuex';
	import axios from "axios";
	import VariablesComponent from '@/components/tmodel/Variables.vue';
	const header = {"Token": localStorage.getItem("token") }
	const configuration = {headers: header}
	import UploadKeyService from "@/services/UploadKeyService";
	export default {
		name: 'TmodelDialog',
		components: {
			VariablesComponent
		},
		data: ()=>({
			uploading: false,
			dialogKeyJson: false,
			variables: [],
			saving: false,
      editedIndex: -1,
      valid: true,
			currentFile: undefined,
      progress: 0,
			fileTitle: '',
			fileName: '',
			message: '',
      editedItem: {
				_id: '',
				project: '',
				projectId: '',
				modelId: '',
				keyJson: '',
				variables: [],
				titleForm: '',
				subtitleForm: '',
				targetVarLabel: '',
				targetVarHint: '',
				runMax: 5,
				runAcum: 0
      },
			rules: {
				required: value => !!value || 'Item is required',
      },
		}),
		mounted(){

		},
		methods: {
			...mapActions('tmodelModule',['getTmodels','addTmodel','updateTmodel','removeFile']),
			closeDialog() {
				this.editedItem = {
					_id: '',
					project: '',
					projectId: '',
					modelId: '',
					keyJson: '',
					variables: [],
					titleForm: '',
					subtitleForm: '',
					targetVarLabel: '',
					targetVarHint: '',
					runMax: 5,
					runAcum: 0
				}
				this.variables = []
        this.$refs.form.resetValidation()
				this.$store.commit('setDialog', false, { root: true })
			},
			validate () {
				if (this.$refs.form.validate()) {
					this.saveTmodel()
				}
			},
			async saveTmodel(){
				this.saving = true;
				this.editedItem.variables = this.variables
				if (this.editMode == 1) {
					const res = await this.updateTmodel(this.editedItem)
				} else {
					const res = await this.addTmodel(this.editedItem)
				}
				this.saving = false;
				this.editedItem={}
				this.$refs.form.resetValidation()
				this.getTmodels()
				this.closeDialog()
			},
			setVariables(data){
				this.variables = data;
			},
			selectFile(file) {
				this.progress = 0;
				this.currentFile = file;
			},

      uploadFile() {
				let me=this;

				UploadKeyService.upload(me.currentFile, (event) => {
					me.progress = Math.round((100 * event.loaded) / event.total);
				})
					.then((response) => {
						me.message = response.data.message;
						if (response.data.code === '200') {
							me.editedItem.keyJson = response.data.result.filename
							me.$store.commit('SET_ALERT_MESSAGE', {
								show: true,
								color: 'success',
								message: "File uploaded, don't forget save the model",
								timeout: 4000
							}, {root: true})
							me.dialogKeyJson = false
							me.$refs.fileData.reset()
						}
					})
					.catch((e) => {
						console.log(e)
						me.progress = 0;
						me.message = "Could not upload the file!";
						me.currentFile = undefined;
						me.saving = false
						this.dialogKeyJson = false
						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'red',
							message: me.message,
							timeout: 4000
						}, {root: true})
						return
					});
			},

			async removeKeyJson(){
				const fileName = this.editedItem.keyJson
				this.removeFile(fileName);
				this.editedItem.keyJson = ''
			},
			downloadKeyJson(fileName){
				alert('Key.json download not implemented')
			}

		},
		computed: {
			...mapState(['loading', 'dialog', 'editMode']),
			...mapState('tmodelModule',['tmodels','tmodel']),
			...mapState('projectModule',['projects','project']),
			formTitle () {
				return this.editMode == 1 ? 'EDIT TEST MODEL' : 'CREATE TEST MODEL'
			},
		},
		watch: {
			'tmodel': function() {
				if (this.tmodel._id != ''){
					this.editedItem = this.tmodel;
					this.variables = this.editedItem.variables;
					//console.log(this.variables)
				}
			},
		}
	}
</script>
