<template>
	<section>
		<h3>Form Variables</h3>
		<div class="d-flex">
			<v-text-field
				class="mx-1"
				dense
				solo
				label="Label"
				v-model="label"
			/>
			<v-text-field
				class="mx-1"
				dense
				solo
				label="Value"
				v-model="value"
			/>
			<v-text-field
				class="mx-1"
				dense
				solo
				label="ColumnSpecId"
				v-model="columnSpecId"
			/>
			<v-btn class="mx-1 success" text @click.prevent="add"><v-icon dark>mdi-plus</v-icon></v-btn>
		</div>
		<v-simple-table v-if="variables.length>=1">
			<template v-slot:default>
				<thead>
					<tr class="grey darken-2">
						<th class="text-center white--text">
							ID
						</th>
						<th class="text-center white--text">
							Label
						</th>
						<th class="text-center white--text">
							Value
						</th>
						<th class="text-center white--text">
							ColumnSpecId
						</th>
						<th width="10%">
							&nbsp;
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(v,i) in variables" :key="i">
						<td class="text-center">{{v.index}}</td>
						<td class="text-center">{{v.label}}</td>
						<td class="text-center">{{v.value}}</td>
						<td class="text-center">{{v.columnSpecId}}</td>
						<td class="text-center"><v-btn class="mx-1" x-small text @click.prevent="remove(i)"><v-icon class="red--text">mdi-trash-can-outline</v-icon></v-btn></td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</section>
</template>

<script>
	export default {
		name: 'tmodelVariables',
		props: ['variables'],
		data: () => ({
			label: '',
			value: '',
			columnSpecId: '',
		}),
		methods: {
			add(){
				let i = this.variables.length+1
				this.variables.push({
					index:i,
					label: this.label,
					value: this.value,
					columnSpecId: this.columnSpecId
				})
				this.$emit('emitVariables', this.variables)
				this.label = ''
				this.value = ''
				this.columnSpecId = ''
			},
			remove(i) {
				this.variables.splice(i,1)
				let tempArray = []
				for (let index = 0; index < this.variables.length; index++) {
					tempArray.push({
						index: index+1,
						label: this.variables[index].label,
						value: this.variables[index].value,
						columnSpecId: this.variables[index].columnSpecId,
					})
				}
				this.variables = tempArray
				this.$emit('emitVariables', this.variables)
			}
		},
		computed: {
		}
	}
</script>

